import React from "react";
import AnswerForm from "../component/answer/AnswerForm";
import "../component/answer/AnswerPage.css"


export default function AnswerPage(){

    return(
        <div className='answer-content'>
            <AnswerForm />
        </div>
    );
}
