import React from "react";

import FindidpwShow from '../component/main/FindidpwShow';

export default function FindidpwPage(){

    return (
        <div className="horazion">
            <FindidpwShow />
        </div>
    );
}
