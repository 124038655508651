import React from "react";

import SignupShow from '../component/main/SignupShow';

export default function SignupPage(){

    return (
        <div className="horazion">
            <SignupShow />
        </div>
    );
}
